<template>
  <v-dialog
    v-model="DialogFatura"
    @click:outside="$emit('update:DialogFatura', false)"
    @keydown.esc="$emit('update:DialogFatura', false)"
    fullscreen
    style="overflow: hidden"
    scrollable
  >
    <v-card flat :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        <v-icon large left>mdi-receipt</v-icon>
        <span class="headline mr-2 ml-2">
          {{ fatura.numero }} - {{ fatura.cliente_nome }}
        </span>
        <v-chip class="ma-2 tocs_gray_1 text-uppercase" label>
          <v-icon
            :color="
              fatura.status === 1
                ? 'tocs_blue'
                : fatura.status === -1
                ? 'tocs_red'
                : fatura.status === 3
                ? 'success'
                : ''
            "
            left
          >
            mdi-label
          </v-icon>
          <template v-if="fatura.status == -1">{{
            $tc("global.cancelada")
          }}</template>
          <template v-if="fatura.status == 1">{{
            $tc("global.aberta")
          }}</template>
          <template v-if="fatura.status == 3">{{
            $tc("global.recebida")
          }}</template>
        </v-chip>

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:DialogFatura', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <!-- HEADER: Dados Fatura -->
          <v-col cols="12">
            <v-subheader
              style="height: auto !important"
              class="pa-2 text-h6 font-weight-bold"
            >
              {{ $tc("global.dadosfatura") }}
              <!-- {{ $tc("global.cliente") }} -->
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :label="$tc('global.data')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.data | dateFormat('dd/MM/yyyy')"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :label="$tc('global.data-emissao')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.data_emissao | dateFormat('dd/MM/yyyy')"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :label="$tc('global.fatura') + ' - ' + $tc('global.numero')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.ponto + '-' + fatura.numero"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              :label="$tc('global.timbrado')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.timbrado"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              :label="$tc('global.condicoespagamento')"
              readonly
              outlined
              dense
              hide-details
              :value="
                fatura.condpag == 1
                  ? $tc('global.aVista')
                  : fatura.condpag == 2
                  ? $tc('global.aPrazo')
                  : ''
              "
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              :label="$tc('global.cliente')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.cliente_nome"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :label="$tc('global.documento')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.cliente_documento"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              :label="$tc('global.endereco')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.cliente_endereco"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              :label="$tc('global.cidade')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.cliente_cidade"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :label="$tc('global.telefone')"
              readonly
              outlined
              dense
              hide-details
              :value="fatura.cliente_telefone"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :label="$tc('global.moeda')"
              readonly
              outlined
              dense
              hide-details
              :value="moedaSigla"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :label="$tc('global.desconto')"
              readonly
              outlined
              dense
              hide-details
              :prefix="moedaSigla"
              v-currency="vCurrencyOptions"
              v-model="fatura.desconto"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :label="$tc('global.valor')"
              readonly
              outlined
              dense
              hide-details
              :prefix="moedaSigla"
              v-currency="vCurrencyOptions"
              v-model="fatura.valor_total"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :value="this.fatura.valor_total_gravado10"
              :label="$tc('global.total') + ' - ' + 'gravado - 10 %'"
              readonly
              outlined
              dense
              hide-details
              :prefix="moedaSigla"
              v-currency="vCurrencyOptions"
            >
            </v-text-field>
          </v-col>
          <!-- HEADER: Dados Fatura -->
          <v-col cols="12">
            <v-subheader
              style="height: auto !important"
              class="pa-2 text-h6 font-weight-bold"
            >
              {{ $tc("global.item", 2) }}
              <!-- {{ $tc("global.cliente") }} -->
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12">
            <v-data-table
              :headers="headersItems"
              :items="fatura.items"
              :items-per-page="10"
              class="data-tables"
              :hide-default-footer="fatura.items.length > 10 ? false : true"
            >
              <template v-slot:item.valor="{ item }">
                <div v-if="fatura.moeda_id == '2'">
                  {{ item.valor | guarani }}
                </div>
                <div v-else>
                  {{ item.valor | currency }}
                </div>
              </template>
              <template v-slot:item.desconto="{ item }">
                <div v-if="fatura.moeda_id == '2'">
                  {{ item.desconto | guarani }}
                </div>
                <div v-else>
                  {{ item.desconto | currency }}
                </div>
              </template>
              <template v-slot:item.total="{ item }">
                <div v-if="fatura.moeda_id == '2'">
                  {{ (item.valor - item.desconto) | guarani }}
                </div>
                <div v-else>
                  {{ (item.valor - item.desconto) | currency }}
                </div>
              </template>
              <template v-slot:item.ivaporc="{ item }">
                {{ item.ivaporc }}%
              </template>
              <template v-slot:item.iva="{ item }">
                <div v-if="fatura.moeda_id == '2'">
                  {{ item.iva | guarani }}
                </div>
                <div v-else>
                  {{ item.valor | currency }}
                </div>
              </template>
            </v-data-table>
          </v-col>

          <v-col class="pa-0">
            <InternFileManager
              v-if="!loading && fatura.timbrado_manual"
              origem="faturas"
              :origem_id="fatura.id"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="fatura.status == '1'">
        <v-btn
          depressed
          small
          @click="cancelDialog = true"
          dark
          color="tocs_red"
        >
          {{ $tc("global.cancelar") }} {{ $tc("global.fatura") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-if="cancelDialog"
      v-model="cancelDialog"
      max-width="500px"
      width="100%"
    >
      <v-card>
        <v-card-title class="text-subtitle-1 primary white--text">
          {{ $tc("global.confirmar") }}
          <v-sheet class="mx-2 py-0 px-2 white tocs_red--text"
            >{{ $tc("global.cancelamento") }}
          </v-sheet>
          {{ $tc("global.fatura") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="cancelDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-textarea
            v-model="fatura.porque_cancelou"
            :label="$tc('global.motivocancelamento')"
            filled
            :rows="3"
            :hint="$tc('global.obrigatorio')"
            persistent-hint
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            small
            class="white--text"
            color="tocs_red"
            :disabled="fatura.porque_cancelou == null ? true : false"
            @click="cancelarFatura"
          >
            {{ $tc("global.confirmar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { fetchFatura, putFatura } from "@/api/faturas/faturas.js";

export default {
  name: "DialogFatura",

  props: {
    DialogFatura: {
      type: Boolean,
      default: false,
    },
    fatura_id: {
      type: [String, Number],
      required: true,
    },
  },

  components: {
    InternFileManager: () =>
      import("@/components/midias/InternFileManager.vue"),
  },

  data() {
    return {
      loading: true,
      cancelDialog: false,
      fatura: {
        items: [],
      },
    };
  },

  watch: {},

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    moedaSigla() {
      let moeda_sigla = "";
      if (this.fatura.moeda_id) {
        moeda_sigla = this.moedas.find(
          (moeda) => moeda.id_moeda === this.fatura.moeda_id
        ).sigla;
      }
      return moeda_sigla;
    },
    vCurrencyOptions() {
      return {
        precision: this.fatura.moeda_id == 2 ? 0 : undefined,
        distractionFree: false,
      };
    },

    headersItems() {
      return [
        {
          text: this.$tc("global.id"),
          value: "id",
        },

        {
          text: this.$tc("global.qtd"),
          align: "start",
          value: "qntde",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
        {
          text: "IVA",
          value: "iva",
        },
        {
          text: this.$tc("global.origem"),
          value: "origem_id",
        },
      ];
    },
  },

  methods: {
    cancelarFatura() {
      let fatura = {};
      fatura.status = -1;
      fatura.porque_cancelou = this.fatura.porque_cancelou;
      fatura.id = this.fatura.id;
      putFatura(fatura.id, fatura)
        .then(() => {
          this.cancelDialog = false;
          this.$emit("fetch-faturas");
          this.$emit("update:DialogFatura", false);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  async mounted() {
    try {
      this.loading = true;
      this.fatura = await fetchFatura(this.fatura_id);
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
